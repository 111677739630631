'use client';

import { FullStory, init } from '@fullstory/browser';
import { createContext, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { FSApi } from '@fullstory/snippet';
import { getFullStoryOptions } from './fullStoryConfig';

export interface ReadyResponse {
    sessionUrl: string;
    settings: Readonly<object>;
}

const FullStoryContext = createContext<FSApi>(null);

export function FullStoryWrapper(props: PropsWithChildren) {
    const options = getFullStoryOptions();

    const initialized = useRef(false);
    const ready = useRef(false);
    const readyResponse = useRef<ReadyResponse>(undefined);

    const [api, setApi] = useState<FSApi>(undefined);

    useEffect(() => {
        // Need options to initialize Full Story
        if (!options) {
            console.log('Full Story: No options to initialize');
            return;
        }

        // Ensure we only initialized once
        if (initialized.current) {
            return;
        }
        initialized.current = true;

        // TODO: this doesn't seem to be working
        const readyCallback = (data: ReadyResponse) => {
            console.log('Full story is ready');
            ready.current = true;
            readyResponse.current = data;
            setApi(FullStory);
        };

        init(options, readyCallback);
        setApi(FullStory);
    }, [options]);

    return (
        <>
            <FullStoryContext.Provider value={api}>{props.children}</FullStoryContext.Provider>
        </>
    );
}

function useFullStoryContext() {
    const context = useContext(FullStoryContext);
    if (context === null) {
        throw new Error('useFullStoryContext must be used within FullStoryReact');
    }
    return context;
}

export function useFullStory(): FSApi {
    const fullStory = useFullStoryContext();

    // TODO: add a shim to queue things up if requests are made before the API is ready

    return fullStory;
}
