'use client';

import { redirect } from 'next/navigation';
import { emptyStringOrDefault } from '@/common/util/strings/emptyStringOrDefault';

export function isLocalhost(): boolean {
    if (typeof window === 'undefined') {
        return false;
    }
    const hostname = emptyStringOrDefault(window?.location?.hostname);
    return hostname.indexOf('localhost') !== -1 || hostname.indexOf('127.0.0.1') !== -1;
}

// For local development, redirect to development hostname
export function LocalHostRedirect() {
    if (isLocalhost()) {
        redirect('https://dev.outageadmin.sites.dteenergy.com/');
    }

    return null;
}
